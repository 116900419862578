<template>
    <div>
<!--        路由实例方法-->
        <el-page-header @back="$router.go(-1)" content="使用说明"/>
        <div><h2 class="biaoti">使用说明(V1.0)</h2></div>
    </div>
</template>

<script>
export default {
    name: 'help',
    path: '/main/help',
    data() {
        return {}
    }
}
</script>

<style lang="scss" scoped>
    .biaoti{
        text-align: center;
    }
    .yijibiaoti{
        font-size: 17px;
        text-align: left;
    }
    .neirong1{
        font-size: 14px;
        text-indent: 2.5em;
    }
    .erjibiaoti{
        font-size: 16px;
        text-indent: 2em;
    }
    img{

        width: 80%;
        height: auto;
        /*测试800是否合适*/
        max-width: 900px;
    }
    .tupian{
        /*修改图片居左*/
        text-indent: 2em;
        text-align:left;
    }
</style>