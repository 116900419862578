const data = {
    table: {
        columns: [
            {title: 'No.', field: 'id', width: '100'},
            {title: '用户名', field: 'username', width: '100'},
            {title: '昵称', field: 'nickname', width: '200'},
            {title: '医院', field: 'tenant_name', width: ''},
            {title: '备注', field: 'remarks', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
