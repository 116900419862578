<template>
    <div class="table-page">
        <div class="page-header">
            <el-page-header @back="$router.go(-1)" content="功能管理" />
        </div>
        <div class="search-box" style="margin-top:20px">
            <el-form :inline="true" style="text-align: center;" size="mini">
                <el-form-item label="输入关键词搜索">
                    <el-input v-model="keywords" clearable placeholder="关键词搜索" style="min-width: 300px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button plain type="primary" icon="el-icon-search" @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" border style="width: 100%" v-loading="loading">
                <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page -1) * table.pageSize)"></div>
                    </template>
                </el-table-column>
                <el-table-column align="left" prop="name" label="医院名称" width="" />
                <el-table-column align="center" label="登记开启" width="">
                    <template slot-scope="scope">
                        <el-switch @change="switchChange(scope.row)" v-model="scope.row.register_on"
                            active-color="#13ce66" inactive-color="#ff4949" />
                    </template>
                </el-table-column>
                <el-table-column align="center" label="接种开启" width="">
                    <template slot-scope="scope">
                        <el-switch @change="switchChange(scope.row)" v-model="scope.row.injection_on"
                            active-color="#13ce66" inactive-color="#ff4949" />
                    </template>
                </el-table-column>
                <el-table-column align="center" label="预约开启" width="">
                    <template slot-scope="scope">
                        <el-switch @change="switchChange(scope.row)" v-model="scope.row.appoint_on"
                            active-color="#13ce66" inactive-color="#ff4949" />
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align: center;margin-top:20px">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]" :total="table.total"
                :page-size="table.pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'admin-hospital-service',
    path: '/main/admin/hospital/service',
    data() {
        return {
            loading: false,
            table: {
                columns: [
                    { title: '医院名称', field: 'name', width: '400' },
                    { title: '地址', field: 'addr', width: '' },
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 50
            },
            keywords: '',
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.table.page = e
            this.getData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async switchChange(row) {
            console.log(row)
            const resp = await this.$http.put(`/hospital/${row.id}`, row)
            if (resp.data.state == 200) {
                this.$message.success("保存成功")
                // this.getData()
            }
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/hospital?keywords=${this.keywords}&page=${this.table.page}&pageSize=${this.table.pageSize}`)
            this.table.total = resp.data.total
            this.table.data = resp.data.data
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>
