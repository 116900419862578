<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="医院">
            <el-select v-model="form.hospital_id" placeholder="请选择医院" clearable style="width: 300px">
                <el-option :label="item.name" :value="item.id" v-for="(item, index) in hospitalList" :key="index">
                </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="输入关键词搜索">
            <el-input v-model="form.keywords" clearable placeholder="关键词搜索" style="min-width: 300px"></el-input>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            hospitalList: [],
            form: {}
        }
    },
    methods: {
        async getData() {
            const hospital = await this.$http.get('/hospital')
            this.hospitalList = hospital.data.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

