const data = {
    table: {
        columns: [
            {title: 'ID', field: 'id', width: '120'},
            {title: '姓名', field: 'name', width: '120'},
            {title: '性别', field: 'gender', width: '120'},
            {title: 'OpenID', field: 'openid', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
