const status = {
    '0': '候诊',
    '1': '叫号',
    '2': '完成',
    '3': '过号'
}

const status_formatter = (row, column) => {
    const value = row[column.property]
    return status[value]
}

const data = {
    table: {
        columns: [
            {title: '序号', field: 'serial', width: ''},
            {title: '姓名', field: 'name', width: ''},
            {title: '体检状态', field: 'tj_status', width: '', formatter: status_formatter},
            {title: '登记台', field: 'tj_tvbox_name', width: ''},
            {title: '登记状态', field: 'status', width: '', formatter: status_formatter},
            {title: '登记台', field: 'tvbox_name', width: ''},
            {title: '接种状态', field: 'jz_status', width: '', formatter: status_formatter},
            {title: '接种台', field: 'jz_tvbox_name', width: ''},
            {title: '取号时间', field: 'date', width: '200'}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 100
    }
}

export default data
