<template>
    <v-chart class="chart" :option="option" />
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
    TitleComponent,
    TooltipComponent,
    LegendComponent
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";

use([
    CanvasRenderer,
    PieChart,
    TitleComponent,
    TooltipComponent,
    LegendComponent
]);

export default {
    name: "scatterChart",
    components: {
        VChart
    },
    provide: {
        [THEME_KEY]: "white"
    },
    data() {
        return {
            option: {
                title: {
                    text: "人员预约时间分布",
                    left: "center"
                },
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b} : {c} ({d}%)"
                },
                xAxis: {
                    type: 'time',
                        name: '时间轴',
                },
                yAxis: {type: 'value',
                        name: '人数值',},
                series: [
                    {
                        symbolSize: 20,
                        data: [
                            ['2022-10-11 08:00:00', Math.floor(Math.random() * 100)+10],
                            ['2022-10-11 08:30:00', Math.floor(Math.random() * 100)+10],
                            ['2022-10-11 09:00:00', Math.floor(Math.random() * 100)+10],
                            ['2022-10-11 09:30:00', Math.floor(Math.random() * 100)+10],
                            ['2022-10-11 10:00:00', Math.floor(Math.random() * 100)+10],
                            ['2022-10-11 10:30:00', Math.floor(Math.random() * 100)+10],
                            ['2022-10-11 11:00:00', Math.floor(Math.random() * 100)+10],
                            ['2022-10-11 11:30:00', Math.floor(Math.random() * 100)+10],
                        ],
                        type: 'scatter'
                    }
                ]
            }
        };
    }
};
</script>

<style scoped>
.chart {
    width: 100%;
    height: 400px;
}
</style>
