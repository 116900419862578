<template>
    <v-chart class="chart" :option="option"/>
</template>

<script>
    import {use} from "echarts/core";
    import {CanvasRenderer} from "echarts/renderers";
    import {LineChart} from "echarts/charts";
    import {GridComponent} from 'echarts/components';
    import {
        TitleComponent,
        TooltipComponent,
        ToolboxComponent,
        LegendComponent
    } from "echarts/components";
    import VChart, {THEME_KEY} from "vue-echarts";

    use([
        CanvasRenderer,
        LineChart,
        GridComponent,
        TitleComponent,
        TooltipComponent,
        ToolboxComponent,
        LegendComponent
    ]);

    export default {
        name: "lineChart",
        components: {
            VChart
        },
        provide: {
            [THEME_KEY]: "light"
        },
        data() {
            return {
                option: {
                    title: {
                        text: '各机构七日体检人数',
                        left: 'center'
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    grid: {
                        left: '5%',
                        right: '14%',
                        bottom: '3%',
                        containLabel: true
                    },
                    toolbox: {
                        feature: {
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [
                        {
                            name: '大庆油田总医院',
                            type: 'line',
                            stack: 'Total',
                            data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), 
                            Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)]
                        },
                        {
                            name: '大庆市人民医院',
                            type: 'line',
                            stack: 'Total',
                            data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), 
                            Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)]
                        },
                        {
                            name: '大庆市龙南医院',
                            type: 'line',
                            stack: 'Total',
                            data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), 
                            Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)]
                        },
                        {
                            name: '大庆市第二医院',
                            type: 'line',
                            stack: 'Total',
                            data: [Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), 
                            Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000), Math.floor(Math.random() * 1000)]
                        },
                    ]
                }
            };
        }
    };
</script>

<style scoped>
    .chart {
        width: 100%;
        height: 400px;
    }
</style>
