<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="姓名">
            <el-input v-model.trim="form.name" clearable />
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: ['search'],
    data() {
        return {
            form: {
                date: this.search.date
            }
        }
    }
}
</script>

