import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

function getRoutes(requireComponent) {
    let routes = []
    requireComponent.keys().forEach(filename => {
        // filename 文件
        // 第i个组件
        const config = requireComponent(filename)
        // 组件名
        if (config.default.path) {
            // console.log(config.default)
            routes.push({
                path: config.default.path,
                name: config.default.name,
                // component: ()=>import(`@/components/${sub.componentName}`)
                component: config.default
            })
        }
    })
    return new Router({
        mode: 'history',
        routes: [
            {path: '/', name: 'index', redirect: '/login'},
            {path: '/login', component: () => import(`@/pages/main/login`)},
            {path: '/logout', component: () => import(`@/pages/main/logout`)},
            {
                path: '/main', component: () => import(`@/pages/main/layout/layout_admin`),
                // 自动发现路由，再扩展几个默认页面
                children: routes.concat([
                    {path: '', name: 'main', redirect: 'dashboard'},
                    {path: 'password', component: () => import(`@/pages/main/password`)},
                    {path: 'message', component: () => import(`@/pages/main/message`)},
                    {path: 'routes', component: () => import(`@/pages/main/getAllRoutes`)},
                    {path: '*', component: () => import(`@/pages/main/notfound`)}
                ]),
            },
            {path: '*', component: () => import(`@/pages/main/notfound`)}
        ]
    })
}

export default getRoutes
