const data = {
    table: {
        columns: [
            {title: '日期', field: 'date', width: '100'},
            {title: '时间', field: 'time', width: '100'},
            {title: '疫苗', field: 'vaccine_name', width: ''},
            {title: '姓名', field: 'patient_name', width: '120'},
            {title: '出生日期', field: 'patient_birth', width: '120'},
            {title: '联系电话', field: 'patient_contact', width: '120'},
            {title: '创建时间', field: 'created', width: '200'}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
