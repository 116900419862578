<template>
    <div>
        <el-row style="margin:0">
            <el-col :span="6" style="padding:10px">
                <count-card title="用户数" count="--" color="rgb(128, 176, 210)" />
            </el-col>
            <el-col :span="6" style="padding:10px">
                <count-card title="用户数" count="--" icon="el-icon-s-shop" color="rgb(141, 210, 198)" />
            </el-col>
            <el-col :span="6" style="padding:10px">
                <count-card title="用户数" count="--" icon="el-icon-s-flag" />
            </el-col>
            <el-col :span="6" style="padding:10px">
                <count-card title="用户数" count="--" icon="el-icon-s-opportunity" color="rgb(144, 147, 153)" />
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <div style="padding: 20px">
                    <h1 style="font-size: 200%">预防接种预约分诊叫号系统</h1>
                    <p>预防接种预约分诊叫号系统</p>
                    <p>
                        <a href="/docs/index.html" target="_blank">使用文档</a>
                    </p>
                </div>
            </el-col>
            <el-col :span="12" style="text-align: right">
                <img src="./images/hero.png" width="500px" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: 'dashboard',
    path: '/main/dashboard',
    data() {
        return {
            counts: {
                total: 0,
                today: 0,
                doctors: 0,
                departments: 0
            }
        }
    }
}
</script>

<style scoped>

</style>