<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="统计报表" />
        <div style="margin-top:20px">
            <i class="el-icon-loading" style="font-size: 300%;"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'statistics',
    path: '/main/statistics',
    data() {
        return {}
    }
}
</script>