<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="预约日期">
            <el-date-picker v-model="form.daterange" size="small" type="daterange" value-format="yyyy-MM-dd"
                range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间" :clearable="false" style="width:240px">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="姓名">
            <el-input v-model.trim="form.patient_name" clearable></el-input>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: ['search'],
    data() {
        return {
            form: {
                daterange: this.search.daterange
            }
        }
    }
}
</script>

