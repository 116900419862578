<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="日期">
            <el-date-picker v-model="form.date" size="small" type="date" value-format="yyyy-MM-dd" :clearable="false" style="width:140px" />
        </el-form-item>
        <el-form-item label="姓名">
            <el-input v-model.trim="form.patient_name" clearable />
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    props: ['search'],
    data() {
        return {
            form: {
                date: this.search.date
            }
        }
    }
}
</script>

