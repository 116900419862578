<template>
    <zk-admin-table title="取号记录" :loading="loading" :table="table" @change="handleTableChange">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-download" circle @click="handleDownload">
                </el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" :search="search" />
        </template>
        <el-table-column type="index" label="序号" fixed="left" :index="(index) => {return (this.table.page - 1) * this.table.pageSize+ index + 1}" width="50">
        </el-table-column>
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import data from './data'

export default {
    name: 'main-queue',
    path: '/main/queue',
    components: {
        SearchForm
    },
    data() {
        const current = new Date()
        const today = current.format('yyyy-MM-dd')
        return {
            loading: false,
            table: data.table,
            searchVisible: true,
            search: {
                date: today
            }
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.getData()
        },
        async handleDownload() {
            this.loading = true
            let params = this.search
            params.export = 'xlsx'//'csv'
            const resp = await this.$http.get(`/queue?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
            let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
            var link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
            link.click()
            window.URL.revokeObjectURL(link.href)
            this.loading = false
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/queue?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data.records
                    this.table.total = resp.data.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
