<template>
    <el-dialog title="编辑" :visible="visible" width="40%" @close="close">
        <el-form :model="form" :rules="rules" ref="form" label-width="120px">
            <el-form-item label="设备标识" prop="name">
                <el-input v-model="form.mac"  style="width:80%"></el-input>
            </el-form-item>
            <el-form-item label="医院" prop="addr">
                <el-select v-model="form.hospital_id" placeholder="请选择医院" style="width: 80%">
                    <el-option :label="item.name" :value="item.id" v-for="(item,idx) in hospitalList" :key="idx">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="名称" prop="name">
                <el-input v-model="form.name" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item label="模板" prop="addr">
                <el-select v-model="form.tmpl_id" placeholder="请选择模板" style="width: 80%">
                    <el-option label="综合列表叫号屏" value="1"></el-option>
                    <el-option label="登记台" value="2"></el-option>
                    <el-option label="留观列表屏" value="3"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="type_id">
                <el-select v-model="form.type_id" placeholder="请选择类型" style="width: 80%">
                    <el-option label="体检" :value="3"></el-option>
                    <el-option label="登记" :value="0"></el-option>
                    <el-option label="接种" :value="1"></el-option>
                    <el-option label="留观" :value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="设备分类" prop="dev_type">
                <el-select v-model="form.dev_type" placeholder="请选择类型" style="width: 80%">
                    <el-option label="显码屏" value="0"></el-option>
                    <el-option label="取号机" value="1"></el-option>
                    <el-option label="呼叫器" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="语音设置" prop="speach_type">
                <el-select v-model="form.speach_type" placeholder="请选择" style="width: 80%">
                    <el-option label="无" :value="0"></el-option>
                    <el-option label="仅自己" :value="1"></el-option>
                    <el-option label="全部语音" :value="2"></el-option>
                    <el-option label="登记语音" :value="3"></el-option>
                    <el-option label="接种语音" :value="4"></el-option>
                    <el-option label="留观语音" :value="5"></el-option>
                    <el-option label="登记和接种语音" :value="6"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="叫码器标识" prop="client_mac" style="width: 100%">
                <el-input v-model="form.client_mac" style="width:80%"></el-input>
            </el-form-item>
            <el-form-item label="体检室" prop="tj_room" style="width: 100%">
                <el-input v-model="form.tj_room" style="width:80%" type="number"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="close">{{$t('form.cancel')}}</el-button>
            <el-button @click="formSubmit" type="primary" :loading="loading" :disabled="loading">{{$t('form.ok')}}
            </el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props: {
        visible: {
            default: false
        },
        form: {
            packages: []
        },
        isNew: {
            default: false
        }
    },
    data() {
        return {
            loading: false,
            hospitalList: [],
            rules: {}
        }
    },
    methods: {
        async formSubmit() {
            const valid = await this.$refs['form'].validate().then(res => res).catch(error => error)
            if (!valid) return
            let method = this.form.id ? `put` : `post`
            let url = '/tvbox/' + (this.form.id ? `/${this.form.id}` : '')
            const resp = await this.$http[method](url, this.form)
            if (resp.data.code != 200) {
                this.$message.error(resp.data.msg)
            } else {
                this.$message.success('保存成功')
                this.$emit('update')
                this.$emit('close')
            }
        },
        close() {
            this.$emit('close')
        },
        async getData() {
            const hospital = await this.$http.get('/hospital')
            this.hospitalList = hospital.data.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
::v-deep .el-dialog__body {
    padding: 0 20px;
}
</style>
