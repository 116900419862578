<template>
    <zk-admin-table title="疫苗设置" :loading="loading" :table="table" @change="handleTableChange"
        @select="handleTableSelect">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-plus" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <search-form @search="handleSearch" v-show="searchVisible" />
        </template>
        <el-table-column type="index" label="序号" align="center"
            :index="(index) => {return (this.table.page - 1) * this.table.pageSize+ index + 1}" width="100" />
        <el-table-column align="left" v-for="(v, idx) in table.columns" :prop="v.field" :label="v.title"
            :width="v.width" :formatter="v.formatter" :key="idx">
        </el-table-column>
        <el-table-column align="center" width="120" label="操作" fixed="right">
            <template slot-scope="scope">
                <el-button type="warning" size="mini" @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>

                <el-popconfirm title="确认删除吗？"  @confirm="handleDelete(scope.row)" style="margin-left:5px">
                    <el-button slot="reference" type="danger" size="mini" icon="el-icon-delete"></el-button>
                </el-popconfirm>
            </template>
        </el-table-column>
        <template v-slot:dialog>
            <data-form :visible="dialogVisible" :form="form" @close="dialogClose" @update="getData" :isNew="isNew">
            </data-form>
        </template>
    </zk-admin-table>
</template>

<script>
import SearchForm from './widgets/searchForm'
import DataForm from './widgets/dataForm'
import data from './data'

export default {
    name: 'settings-appoint-vaccine',
    path: '/main/settings/appoint/vaccine',
    components: {
        SearchForm,
        DataForm
    },
    data() {
        return {
            loading: false,
            table: data.table,
            dialogVisible: false,
            searchVisible: true,
            search: {},
            form: {},
            isNew: false,
            multipleSelection: []
        }
    },
    methods: {
        dialogClose() {
            this.dialogVisible = false
            this.getData()
        },
        doDownLoad() {
            this.downloadVisible = true
        },
        handleSearch(e) {
            this.search = e
            this.table.page = 1
            this.getData()
        },
        handleTableChange(e) {
            this.table = e
            this.getData()
        },
        handleTableSelect(val) {
            this.multipleSelection = val
        },
        handleAdd() {
            this.form = { name: '', sort: 0, quota: 10, total: 100 }
            this.isNew = true
            this.dialogVisible = true
        },
        async handleUpdate(row) {
            this.isNew = false
            var temp = JSON.stringify(row)
            this.form = JSON.parse(temp)
            this.dialogVisible = true
        },
        async handleDelete(row) {
            this.loading = true
            await this.$http.delete(`/vaccine/${row.id}`)
            this.$message.success('删除成功')
            this.getData()
            this.loading = false
        },
        async getData() {
            this.loading = true
            try {
                const resp = await this.$http.get(`/vaccine?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: this.search })
                if (!resp.data) {
                    this.$message.error(resp.data.msg)
                } else {
                    this.table.data = resp.data.data
                    this.table.total = resp.data.total
                }
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>
