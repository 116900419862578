<template>
    <zk-search-form-inline :form="form" @search="$emit('search', form)">
        <el-form-item label="关键词">
            <el-input v-model.trim="form.keywords" clearable></el-input>
        </el-form-item>
    </zk-search-form-inline>
</template>

<script>
export default {
    data() {
        return {
            form: {}
        }
    }
}
</script>

