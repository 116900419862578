<template>
    <div class="table-page">
        <div class="page-header">
            <el-page-header @back="$router.go(-1)" content="二维码管理" />
        </div>
        <div class="search-box" style="margin-top:20px">
            <el-form :inline="true" style="text-align: center;" size="mini">
                <el-form-item label="输入关键词搜索">
                    <el-input v-model="keywords" clearable placeholder="关键词搜索" style="min-width: 300px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button plain type="primary" icon="el-icon-search" @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="page-main">
            <el-table stripe :data="table.data" style="width: 100%" v-loading="loading">
                <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page - 1) * table.pageSize)"></div>
                    </template>
                </el-table-column>
                <el-table-column align="left" prop="name" label="医院名称" width="" />
                <el-table-column align="center" label="二维码" width="">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row, 'tab1')" size="small"
                            icon="el-icon-c-scale-to-original">
                            首页
                        </el-button>
                        <el-button @click="handleUpdate(scope.row, 'register')" size="small"
                            icon="el-icon-c-scale-to-original">
                            取号页
                        </el-button>
                        <el-button @click="handleUpdate(scope.row, 'appoint')" size="small"
                            icon="el-icon-c-scale-to-original">
                            预约页
                        </el-button>
                        <el-button @click="handleUpdate(scope.row, 'history')" size="small"
                            icon="el-icon-c-scale-to-original">
                            查记录页
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align: center;margin-top:20px">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]" :total="table.total"
                :page-size="table.pageSize" @current-change="handleCurrentChange" @size-change="handleSizeChange" />
        </div>
        <el-dialog title="二维码预览" :visible.sync="dialogVisible" width="40%" center>
            <div style="text-align:center">
                <img :src="qr_url" style="max-width: 350px;">
                <div>链接地址：{{ qr_url_raw }}</div>
                <div>右键点击二维码图片选择另存为下载二维码</div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import loadingGif from './images/loading.gif'
export default {
    name: 'admin-hospital-qrcode',
    path: '/main/admin/hospital/qrcode',
    data() {
        return {
            loading: false,
            table: {
                columns: [
                    { title: '医院名称', field: 'name', width: '400' },
                    { title: '地址', field: 'addr', width: '' }
                ],
                data: [],
                total: 0,
                page: 1,
                pageSize: 50
            },
            keywords: '',
            dialogVisible: false,
            qr_url: loadingGif,
            qr_url_raw: ''
        }
    },
    methods: {
        handleCurrentChange(e) {
            this.table.page = e
            this.getData()
        },
        handleSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        handleUpdate(row, target = 'tab1') {
            this.qr_url = loadingGif
            this.dialogVisible = true
            this.qr_url = `/api/hospital/qrcode/${target}/${row.id}`
            this.qr_url_raw = `https://wx.butsoon.com/m/${row.id}/${target}`
        },
        async switchChange(row) {
            console.log(row)
            const resp = await this.$http.put(`/hospital/${row.id}`, row)
            if (resp.data.state == 200) {
                this.$message.success('保存成功')
                // this.getData()
            }
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/hospital?keywords=${this.keywords}&page=${this.table.page}&pageSize=${this.table.pageSize}`)
            this.table.total = resp.data.total
            this.table.data = resp.data.data
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>
