<template>
    <el-dialog :title="`排期: ${form.date}`" :visible="dialogVisible" width="60%" @close="close" :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="form">
            <el-form-item label="疫苗" prop="times">
                <el-checkbox-group v-model="form.vaccine">
                    <el-checkbox :label="e.id" name="vaccine" v-for="(e,i) in vaccineList" :key="i">{{e.name}}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
            <el-form-item label="分时设置" prop="times">
                <time-inputs v-model="form.times"></time-inputs>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="danger" @click="remove" v-if="form.id" style="float:left">删 除</el-button>
            <el-button @click="close">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>

export default {
    props: {
        form: {
            type: Object
        },
        dialogVisible: {
            default: false
        }
    },
    data() {
        return {
            vaccineList: [],
            current_form: {
                date: '',
                vaccine: [],
                times: []
            },
            rules: {}
        }
    },
    methods: {
        async submit() {
            const resp = await this.$http.post('/worksheet/', this.form)
            console.log(resp)
            this.$emit('close')
            this.$emit('update')
        },
        async remove() {
            const resp = await this.$http.delete(`/worksheet/${this.form.id}`)
            console.log(resp)
            this.$emit('close')
            this.$emit('update')
        },
        close() {
            this.$emit('close')
        },
        async getData() {
            const resp = await this.$http.get('/vaccine')
            this.vaccineList = resp.data.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style scoped>
</style>