const data = {
    table: {
        columns: [
            {title: '名称', field: 'name', width: ''},
            {title: '限额', field: 'quota', width: '180'},
            {title: '库存', field: 'total', width: '180'},
            {title: '排序', field: 'sort', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
