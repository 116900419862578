<template>
    <el-form :inline="true" :model="form" class="form demo-form-inline" size="small" @submit.native.prevent style="margin-left: 40px;">
        <slot></slot>
        <el-form-item>
            <el-button plain type="primary" icon="el-icon-search" @click="doSearch" native-type="submit" :disabled="disabled">
                搜索
            </el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    export default {
        name: 'zk-search-form-inline',
        props: {
            form: {
                default: {}
            },
            disabled: null
        },
        methods: {
            doSearch() {
                this.$emit('search', this.form)
            }
        }
    }
</script>

<style scoped>
    .form {
        padding:4px;
    }

    /deep/ .el-form-item {
        margin: 3px;
    }
</style>
