import axios from 'axios'

const DEV_BASE_URL = '/api'
const PRO_BASE_URL = '/api'

axios.defaults.baseURL =
    process.env.NODE_ENV === 'production' ? PRO_BASE_URL : DEV_BASE_URL

axios.defaults.timeout = 50000

// 替代 qs.stringify(
function serializeParams(params) {
    const searchParams = new URLSearchParams();
    for (const key in params) {
        searchParams.append(key, params[key]);
    }
    return searchParams.toString();
}

// http request 拦截器
axios.interceptors.request.use(
    config => {
        config.paramsSerializer = (params) => {
            return serializeParams(params)
        }
        // 判断是否存在token，如果存在的话，则每个http header都加上token
        let token = localStorage.getItem('token')
        if (token) {
            // config.headers.Authorization = `Bearer ${token}`
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    err => {
        return Promise.reject(err)
    }
)

// http response 拦截器
axios.interceptors.response.use(
    response => {
        return response
    },
    error => {
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    // 返回 401 清除token信息并跳转到登录页面
                    // store.commit(types.LOGOUT);
                    // router.replace({
                    //     path: '/login',
                    //     query: {
                    //         // redirect: router.currentRoute.fullPath
                    //     }
                    // })
                    window.location.href = '/'
                    break
                case 403:
                    return Promise.reject(new Error(`权限错误： ${error.response.status}`))
                case 404:
                    return Promise.reject(new Error(`服务端错误： ${error.response.status}`))
                case 500:
                    return Promise.reject(new Error(`服务端错误： ${error.response.status}`))
            }
        }
        // 返回接口返回的错误信息
        return Promise.reject(new Error('未知错误'))
    }
)

axios.restPostOrPut = (api, data) => {
    let method = data.id ? 'put' : 'post'
    let url = (method === 'put') ? api + data.id : api
    return new Promise((resolve, reject) => {
        axios[method](url, data).then(res => {
            resolve(res.data)
        }).catch(e => {
            reject(e)
        })
    })
}

export default axios
