<template>
    <div>
        <div class="page-header hidden-sm-and-down">
            <div style="float:right">
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="预约排期" />
        </div>
        <el-row>
            <el-col :span="14">
            </el-col>
            <el-col :span="10" style="text-align: right">
                <!-- <el-button type="primary" size="small" @click="newPaiban" icon="el-icon-plus" plain>新建排班</el-button> -->
            </el-col>
        </el-row>
        <div style="text-align: ;center">
            <el-form size="small" :inline="true" style="text-align: center;">
                <el-form-item>
                    <el-button type="default" size="small" @click="getData" plain>上个月</el-button>
                </el-form-item>
                <el-form-item>
                    <el-date-picker v-model="form.date" type="month" size="small" placeholder="选择月" format="yyyy年-MM月"
                        :clearable="false" value-format="yyyy-MM-dd" @change="getData" :editable="false"
                        class="mydate-picker" />
                </el-form-item>
                <el-form-item>
                    <el-button type="default" size="small" @click="getData" plain>下个月</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="calendar">
            <div v-for="h in calendar.header" :key="h.ID" class="box header">{{h}}</div>
            <div v-for="(item, index) in calendar.data" :key="index" class="box" @click="selectDate(item)">
                <!-- <div>{{item.date}}</div> -->
                <div :class="getClass(item.date)">
                    {{item.date|getDay}}
                </div>
                <div v-if="item.dic" style="font-size:80%;color:#666">
                    <div>疫苗种类：{{item.dic.vaccine.length}}</div>
                    <div>可约时段：{{item.dic.times.length}}</div>
                </div>
            </div>
        </div>
        <dialog-form :dialogVisible="dialogVisible" @close="dialogVisible = false" :form="form" @update="getData"/>
    </div>
</template>
<script>
import dialogForm from './widgets/dialogForm'
export default {
    name: 'settings-appoint-calendar',
    path: '/main/settings/appoint/calendar',
    components: {
        dialogForm
    },
    data() {
        let now = new Date()
        let today = now.format('yyyy-MM-dd')
        return {
            form: {
                date: today
            },
            dialogVisible: false,
            calendar: {
                header: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
                data: []
            }
        }
    },
    filters: {
        getDay(date) {
            let d = date.split('-')
            return d[2]
        }
    },
    methods: {
        getClass(key) {
            if (key.slice(0, 7) === this.form.date.slice(0, 7)) {
                return 'day'
            }
            return 'day light'
        },
        async getData() {
            const resp = await this.$http.get('/worksheet/calendar', { params: this.form })
            console.log(resp.data.dates)
            this.calendar.data = resp.data.dates
            console.log(resp)
        },
        async selectDate(e) {
            this.form = e.dic || {vaccine: [], times: [
                {time:'08:00-08:30', count: 20},
                {time:'08:30-09:00', count: 20},
                {time:'09:00-09:30', count: 20},
                {time:'09:30-10:00', count: 20},
                {time:'10:00-10:30', count: 10},
                {time:'10:30-11:00', count: 3}
            ]}
            this.form.date = e.date
            this.dialogVisible = true
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.mydate-picker {
    width: 140px;
}

::v-deep .mydate-picker input {
    cursor: pointer;
}

a {
    text-decoration: none;
    color: #333;
}

.calendar {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;

    .header {
        font-weight: bold;
        font-size: 110%;
        line-height: 50px;
        min-height: 50px !important;
    }

    .box {
        width: 14.28%;
        min-height: 100px;
        /*margin-bottom: 2px;*/
        border-bottom: 1px solid #ccc;
        cursor: pointer;

        .day {
            padding-top: 10px;
            font-size: 150%;
        }

        .light {
            color: #aaa;
        }
    }
    .box:hover {
        background-color: #ecf5ff;
    }
}
</style>