<template>
    <div>
        <el-page-header @back="$router.go(-1)" content="租户管理" />
        <div style="margin-top:20px">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="切换医院">
                    <el-select v-model="form.tenant_id" placeholder="请选择租户">
                        <el-option :label="e.name" :value="e.id" v-for="(e,i) in hospitalList" :key="i"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmit">切换租户</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'admin-tenant',
    path: '/main/admin/tenant',
    data() {
        return {
            hospitalList:[],
            form: {tenant_id:null}
        }
    },
    methods: {
        async onSubmit() {
            console.log(this.form)
            const resp = await this.$http.post('/tenant/', this.form)
            if (resp.data.code == 200) {
                this.$store.commit('set_token', resp.data.token)
                this.$message.success('切换成功')
                this.getData()
            } else {
                this.$message.error('切换失败:'+resp.data.msg)
            }
        },
        async getData() {
            const hs = await this.$http.get('/hospital?page=1&pageSize=100')
            this.hospitalList = hs.data.data
            const resp = await this.$http.get('/tenant/')
            this.form.tenant_id = resp.data.tenant_id+''
            console.log(this.form)
        }
    },
    mounted() {
        this.getData()
    }
}
</script>