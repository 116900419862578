const type_id = {
    '3': '体检',
    '0': '登记',
    '1': '接种',
    '2': '留观'
}

const tmpl_id = {
    '1': '综合列表叫号屏',
    '2': '登记台',
    '3': '留观列表屏'
}

const dev_type = {
    '0': '显码屏',
    '1': '取码机',
    '2': '呼码器'
}

const type_id_formatter = (row, column) => {
    const value = row[column.property]
    return type_id[value] || value
}


const dev_type_formatter = (row, column) => {
    const value = row[column.property]
    return dev_type[value] || value
}

const tmpl_id_formatter = (row, column) => {
    const value = row[column.property]
    return tmpl_id[value] || value
}

const data = {
    table: {
        columns: [
            { title: '设备标识', field: 'mac', width: '150' },
            { title: '软件版本', field: 'ver', width: '' },
            { title: '名称', field: 'name', width: '' },
            { title: '设备分类', field: 'dev_type', width: '', formatter: dev_type_formatter },
            { title: '类型', field: 'type_id', width: '', formatter: type_id_formatter },
            { title: '模板', field: 'tmpl_id', width: '', formatter: tmpl_id_formatter },
            { title: '叫码器标识', field: 'client_mac', width: '150' },
            { title: '体检室', field: 'tj_room', width: '' }
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
