<template>
    <zk-admin-table title="医院管理" :loading="loading" :table="table">
        <template v-slot:buttons>
            <el-row style="float: right">
                <el-button size="mini" icon="el-icon-search" circle @click="searchVisible=!searchVisible"></el-button>
                <el-button size="mini" icon="el-icon-refresh" circle @click="getData"></el-button>
                <el-button size="mini" icon="el-icon-plus" type="primary" circle @click="handleAdd"></el-button>
            </el-row>
        </template>
        <template v-slot:search>
            <el-form :inline="true" v-show="searchVisible" size="mini">
                <el-form-item label="输入关键词搜索">
                    <el-input v-model="keywords" clearable placeholder="关键词搜索" style="min-width: 300px"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button plain type="primary" icon="el-icon-search" @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </template>
                <!-- <el-table-column type="index" width="50" align="center">
                    <template slot-scope="scope">
                        <div v-text="scope.$index + 1 + ((table.page -1) * table.pageSize)"></div>
                    </template>
                </el-table-column> -->
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)"
                                   size="small"
                                   type="warning"
                                   icon="el-icon-edit">
                        </el-button>
                        <el-button @click="handleDel(scope.row)" size="small" type="danger"
                                   icon="el-icon-delete"></el-button>
                    </template>
                </el-table-column>
        <template v-slot:dialog>
        <el-dialog title="设置" :visible.sync="dialogVisible" width="40%" center>
            <el-form :model="form" :rules="rules" ref="form" label-width="120px">
                <el-form-item label="名称" prop="name">
                    <el-input v-model="form.name" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="地址" prop="addr">
                    <el-input v-model="form.addr" style="width:80%"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="desc">
                    <el-input v-model="form.desc" style="width:80%"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </span>
        </el-dialog>
        </template>
    </zk-admin-table>
</template>

<script>
    export default {
        name: 'admin-hospital',
        path: '/main/admin/hospital/index',
        data() {
            return {
                loading: false,
                searchVisible: true,
                table: {
                    columns: [
                        {title: '租户ID', field: 'id', width: '100'},
                        {title: '医院名称', field: 'name', width: '400'},
                        {title: '地址', field: 'addr', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 20
                },
                keywords: '',
                dialogVisible: false,
                form: {
                    name: '',
                    addr: '',
                    desc: ''
                },
                rules: {
                    username: [
                        {required: true, message: '请输入账号', trigger: 'blur'},
                    ]
                }
            }
        },
        methods: {
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleAdd() {
                this.form = {name: '', desc: '', sort: 0}
                this.dialogVisible = true
            },
            handleUpdate(row) {
                this.form = row
                this.dialogVisible = true
            },
            async handleDel(row) {
                const confirm = await this.$confirm(`确认删除 ${row.name} 的记录吗？`)
                if (!confirm) return
                const resp = await this.$http.delete(`/hospital/${row.id}`)
                if (resp.data.state == 200) {
                    this.$message.success("删除成功")
                    this.getData()
                    this.dialogVisible = false
                }
            },
            async handleSubmit() {
                const valid = await this.$refs.form.validate().then(res => res).catch(error => error);
                if (!valid) return
                let method = this.form.id ? `put` : `post`
                let url = '/hospital' + (this.form.id ? `/${this.form.id}` : '')
                const resp = await this.$http[method](url, this.form)
                if (resp.data.state == 200) {
                    this.$message.success("保存成功")
                    this.getData()
                    this.dialogVisible = false
                }
            },
            async getData() {
                this.loading = true
                const resp = await this.$http.get(`/hospital?keywords=${this.keywords}&page=${this.table.page}&pageSize=${this.table.pageSize}`)
                this.table.total = resp.data.total
                this.table.data = resp.data.data
                this.loading = false
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style scoped>
    /deep/ .el-form-item {
        margin: 5px;
    }
</style>
