export default {
    app_title: '接种管理程序',
    app: {
        title: '接种预约叫号',
        home: '首页',
        passwd: '修改密码',
        logout: '退出'
    },
    menu: {
        dashboard: '系统首页',
        appoint: '预约记录',
        queue: '取号记录',
        patient: '受种者管理',
        statistics: '统计报表',
        settings: '系统设置',
        settings_appoint_vaccine: '疫苗设置',
        settings_appoint_calendar: '预约排期',
        settings_queue: '取号设置',
        admin: '管理员菜单',
        admin_tenant: '租户管理',
        admin_hospital: '医院设置',
        admin_hospital_service: '功能设置',
        admin_hospital_qrcode: '二维码设置',
        admin_hospital_tvbox: '盒子设置',
        admin_user: '账号设置',
        oplog: '操作日志',
        help: '使用说明'
    },
    form: {
        search: '搜索',
        ok: '确认',
        cancel: '取消',
        export_xsl: '导出Excel'
    }
}
