<template>
    <el-select :value="value" @input="$emit('input', $event)" placeholder="请选择" :size="size" clearable>
        <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id" />
    </el-select>
</template>

<script>
export default {
    name: 'selectHospital',
    props: {
        value: {
            type: [String, Number, Object]
        },
        size: {
            default: 'small'
        }
    },
    data() {
        return {
            options: []
        }
    },
    methods: {
        async getData() {
            const hl = await this.$http.get('/hospital?page=1&pageSize=200')
            this.options = hl.data.data
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>

</style>
