import Mock from 'mockjs'

const dastboard = {
    id: '10',
    label: 'menu.dashboard',
    path: '/main/dashboard',
    icon: 'el-icon-view'
}

const appoint = {
    id: '20',
    label: 'menu.appoint',
    path: '/main/appoint',
    icon: 'el-icon-time'
}

const queue = {
    id: '30',
    label: 'menu.queue',
    path: '/main/queue',
    icon: 'el-icon-odometer'
}

const patient = {
    id: '40',
    label: 'menu.patient',
    path: '/main/patient',
    icon: 'el-icon-user'
}

// eslint-disable-next-line
const statistics = {
    id: '50',
    label: 'menu.statistics',
    path: '/main/statistics',
    icon: 'el-icon-pie-chart'
}

const settings = {
    id: '60',
    label: 'menu.settings',
    path: '/main/settings',
    icon: 'el-icon-setting',
    children: [
        {
            id: '60-1',
            label: 'menu.settings_appoint_vaccine',
            path: '/main/settings/appoint/vaccine'
        },
        {
            id: '60-2',
            label: 'menu.settings_appoint_calendar',
            path: '/main/settings/appoint/calendar'
        },
        {
            id: '60-3',
            label: 'menu.settings_queue',
            path: '/main/settings/queue'
        },
    ]
}

const admin = {
    id: '100',
    label: 'menu.admin',
    path: '/main/admin',
    icon: 'el-icon-collection',
    children: [
        {
            id: '100-0',
            label: 'menu.admin_tenant',
            path: '/main/admin/tenant'
        },
        {
            id: '100-1',
            label: 'menu.admin_hospital',
            path: '/main/admin/hospital/index'
        },
        {
            id: '100-2',
            label: 'menu.admin_hospital_service',
            path: '/main/admin/hospital/service'
        },
        {
            id: '100-3',
            label: 'menu.admin_hospital_qrcode',
            path: '/main/admin/hospital/qrcode'
        },
        {
            id: '100-4',
            label: 'menu.admin_hospital_tvbox',
            path: '/main/admin/hospital/tvbox'
        },
        {
            id: '100-4',
            label: 'menu.admin_user',
            path: '/main/admin/user'
        }
    ]
}

const oplog = {
    id: '120',
    label: 'menu.oplog',
    path: '/main/oplog',
    icon: 'el-icon-notebook-1'
}

const help = {
    id: '200',
    label: 'menu.help',
    path: '/main/help',
    icon: 'el-icon-help'
}

let data = {
    getMenuItems() {
        let menu = []
        menu.push(dastboard)
        menu.push(appoint)
        menu.push(queue)
        menu.push(patient)
        // menu.push(statistics)
        menu.push(settings)
        if (localStorage.getItem('username') == 'admin') {
            menu.push(admin)
            menu.push(oplog)
        }
        menu.push(help)
        return menu
    }
}


Mock.mock('/api/menu', () => {
    return { code: 200, data: data.getMenuItems() }
})