import Vue from 'vue'

import { use } from "echarts/core";
import {
    PieChart,
    BarChart,
    LineChart,
    ScatterChart,
    HeatmapChart,
    GaugeChart
} from 'echarts/charts';

import {
    VisualMapComponent,
    CalendarComponent,
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    MarkLineComponent,
    MarkAreaComponent,
    MarkPointComponent,
    GraphicComponent
} from 'echarts/components';

import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

use([
    TitleComponent,
    ToolboxComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BrushComponent,
    MarkLineComponent,
    MarkAreaComponent,
    MarkPointComponent,
    VisualMapComponent,
    CalendarComponent,
    GraphicComponent,

    PieChart,
    BarChart,
    LineChart,
    ScatterChart,
    GaugeChart,
    HeatmapChart,

    CanvasRenderer,
    UniversalTransition
]);

import VueEcharts from 'vue-echarts';
Vue.component('v-chart', VueEcharts);
