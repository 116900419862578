const data = {
    table: {
        columns: [
            {title: '时间', field: 'created', width: '180'},
            {title: '对象', field: 'target', width: '180'},
            {title: '用户', field: 'user', width: '180'},
            {title: '内容', field: 'content', width: ''}
        ],
        data: [],
        total: 0,
        page: 1,
        pageSize: 20
    }
}

export default data
